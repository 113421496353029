/* ************************************************************************** */
/*                                                                            */
/*                                                        :::      ::::::::   */
/*   Header.css                                         :+:      :+:    :+:   */
/*                                                    +:+ +:+         +:+     */
/*   By: jmykkane <jmykkane@student.hive.fi>        +#+  +:+       +#+        */
/*                                                +#+#+#+#+#+   +#+           */
/*   Created: 2023/11/05 11:53:44 by jmykkane          #+#    #+#             */
/*   Updated: 2023/11/21 21:43:00 by jmykkane         ###   ########.fr       */
/*                                                                            */
/* ************************************************************************** */

.Header {
	justify-content: space-between;
	box-sizing: border-box;
	width: calc(100% - 5vh);
	padding-left: 5vh;
	padding-right: 5vh;
	padding-top: 3vh;
	position: absolute;
	display: flex;
	height: 6vh;
	z-index: 10;
}

.Header a {
	font-family: 'Monaco', 'Monospace';
	text-decoration: none;
	color: var(--primary);
	font-size: 20px;
	
	transition: transform 0.2s ease;
}

.Header a:hover {
	transform: scale(1.1);
}