/* ************************************************************************** */
/*                                                                            */
/*                                                        :::      ::::::::   */
/*   App.css                                            :+:      :+:    :+:   */
/*                                                    +:+ +:+         +:+     */
/*   By: jmykkane <jmykkane@student.hive.fi>        +#+  +:+       +#+        */
/*                                                +#+#+#+#+#+   +#+           */
/*   Created: 2023/11/02 07:09:05 by jmykkane          #+#    #+#             */
/*   Updated: 2024/04/08 15:34:57 by jmykkane         ###   ########.fr       */
/*                                                                            */
/* ************************************************************************** */

/* FONT IMPORT */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

/* GENERAL */
.App {
	background: var(--secondary-bg);
	position: fixed;
	width: 100vw;
	max-width: 100vw;
	left: 0;
	top: 0;
}

.App-content {
	width: calc(100vw - 6vh);
	overflow-y: scroll;
	padding-right: 3vh;
	padding-left: 3vh;
}

.App-content-top {
    background: var(--secondary-bg);
    position: sticky;
    z-index: 10;
    height: 3vh;
	  right: 0;
	  left: 0;
    top: 0;
}

.App-content-bottom {
	background: var(--secondary-bg);
	position: sticky;
	height: 3vh;
	z-index: 10;
	bottom: 0;
	right: 0;
	left: 0;
}

/* HIDING SCROLL BAR */
.App-content::-webkit-scrollbar { /* Safari and Chrome */
	display: none;
}
.App-content { /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}
.App-content::-ms-Scrollbar { /* Edge */
    display: none;
}