/* ************************************************************************** */
/*                                                                            */
/*                                                        :::      ::::::::   */
/*   Landing.css                                        :+:      :+:    :+:   */
/*                                                    +:+ +:+         +:+     */
/*   By: jmykkane <jmykkane@student.hive.fi>        +#+  +:+       +#+        */
/*                                                +#+#+#+#+#+   +#+           */
/*   Created: 2023/11/05 11:57:32 by jmykkane          #+#    #+#             */
/*   Updated: 2024/04/08 15:35:26 by jmykkane         ###   ########.fr       */
/*                                                                            */
/* ************************************************************************** */

.Landing {
	position: relative;
	margin-top: -5vh;
	display: flex;
	height: 100vh;
  overflow-y: hidden;
	width: calc(100vw - 6vh);
	box-sizing: border-box;
	z-index: 5;

	background: var(--background);
}

.Landing-right {
	padding-left: 50vw;
	position: absolute;
	height: 100vh;
	width: calc(100vw - 6vh);
	box-sizing: border-box;
	margin-left: -5vh;
	z-index: 6;
    left: 0;
    top: 0;
}

.Landing-left {
	position: absolute;
	left: 0;
	top: 0;
	padding-left: 5vh;
	padding-top: 33vh;
	line-height: 1;
	height: 100vh;
	z-index: 10;
}

.Landing-left h1 {
	font-family: 'Merriweather', serif;
	font-weight: 400;
	font-size: 64px;
	
	color: var(--primary);
}

.Landing-left b {
	font-family: 'Merriweather', serif;
	font-weight: 900;
	font-size: 64px;
	
	color: var(--detail);
}

.Landing-left span {
	font-family: 'Merriweather', serif;
	margin-left: -10px;
	font-size: 64px;
	color: var(--highlight);
}

.Landing-left p {
	font-family: 'Merriweather', serif;
	margin-top: -2vh;
	font-size: 24px;
	
	color: var(--primary);
}

.span-highlight {
	font-family: 'Merriweather', serif;
	font-size: 24px !important;
	padding-left: 17px;
	padding-right: 7px;
	
	color: var(--detail) !important;
}

/* Scale for smaller screens */
@media (max-width: 1015px) {
	.Landing {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.Landing-right,
	.Landing-left {
		justify-content: center;
		align-items: center;
		position: static;
		padding-top: 5vh;
		display: flex;
		height: auto;
		width: auto;
		z-index: 1;
	}

	.Landing-right {
		padding-bottom: 0;
		margin-bottom: 0;
		padding-right: 0;
		margin-left: 0;
		order: -1;
	}

	.Landing-left {
		margin-top: -10vh;
		padding-top: 0;
		padding-left: 1vh;
		padding-right: 1vh;
	}

	.Landing-left p,
	.Landing-left span {
		display: none;
		color: var(--highlight);
	}

	.Landing-left b,
	.Landing-left h1 {
		font-family: 'Merriweather', serif;
		font-size: 46px;
		
		color: var(--primary);
	}
	
	.Character {
		height: 100%;
	}
}
